$border-radius-base: 4px;

// Colors
$brand-primary: #dd4b63;
$brand-secondary: #f1e6ed;
$brand-complementary: #303640;
$body-bg: #f8f1f8;
$text-color: #64646d;
$headings-color: $brand-complementary;
$headings-small-color: inherit;
$link-color: $brand-primary;
$link-hover-decoration: none;
$input-color: $text-color;

// Typography
$font-size-base: 16px;
$font-size-large: ceil(($font-size-base * 1.1));
$font-size-small-unit: 0.875rem;
$em-base: $font-size-base;
$font-weight-base: 300;
$line-height-base: 1.5;
$headings-font-weight: 300;
$headings-line-height: 1.4;
$font-weight-bold: 400;
$pasja-letter-spacing: 0.095em; // This variable is defined also in theme.scss.liquid and both values should be the same.

// Dropdown
$dropdown-font-size: 1rem;
$dropdown-link-hover-color: $link-color;
$dropdown-border: rgba(#000, 0.02);
$dropdown-link-color: inherit;
$dropdown-link-hover-bg: transparent;

// Layout
$grid-gutter-width: 30px;
$grid-gutter-height: 30px;
$container-desktop: (940px + $grid-gutter-width);
$container-large-desktop: $container-desktop;
$base-spacing: $grid-gutter-height;
$padding-large-horizontal: 36px;
$pasja-box-shadow-offset-base: 20px;
$m-nav-toggle-font-size: 30px;
$mobile-navigation-breakpoint-max: none;

// Map
$m-map-height: 460px;

// Form
$input-border-radius: 0;
$input-border: rgba(#000, 0.1);
$input-border-shorthand: 2px solid $input-border;
$input-box-shadow: none;
$labels-font-weight: $font-weight-base;
$m-checkbox-font-weight: 300;

// Buttons
$btn-default-border: rgba(#000, 0.1);
$btn-text-transform: uppercase;
$btn-padding-base-horizontal: 22px;
$btn-padding-large-horizontal: 36px;
$btn-font-size-base: 1rem;
$btn-font-size-large: 1.125rem;
$m-button-default-selectors: ".cart-button", ".btn-creator";
$m-button-primary-selectors: ".add-button", ".btn-checkout", ".button";

// List module
$m-list-title-line-height: 1; // TODO: Compare with accordion header
$m-list-title-font-size: 1rem;
$m-list-title-font-weight: 400;
$m-list-title-text-transform: uppercase;
$m-list-item-border-top: 1px solid $brand-secondary; // border-color is defined in theme.scss.liquid
$m-list-last-border-bottom: $m-list-item-border-top;

// Item module
$m-item-title-font-size: 1rem;
$m-item-title-text-transform: uppercase;
$m-item-description-font-size: 0.8125rem;
$m-item-price-font-color: $brand-complementary;

// Group module
$m-group-header-font-size: $m-list-title-font-size;
$m-group-header-link-padding: floor($grid-gutter-width / 1.5);
$m-group-header-text-transform: $m-list-title-text-transform;
$m-group-header-link-hover-color: $link-color;
$m-group-header-font-weight: $m-list-title-font-weight;

// Group nav module
$m-group-nav-text-transform: $m-list-title-text-transform;
$m-group-nav-link-padding: 0.7em;
$m-group-nav-link-hover-color: $link-color;

// Select
$m-select-padding: 6px 36px 6px 12px;
$m-select-caret: (
  position: absolute,
  top: 50%,
  right: 10px,
  margin-top: -12px,
  font-family: "FontAwesome",
  font-style: normal,
  font-weight: normal,
  speak: none,
  display: inline-block,
  text-decoration: inherit,
  font-variant: normal,
  text-transform: none,
  -webkit-font-smoothing: antialiased,
  -moz-osx-font-smoothing: grayscale,
  color: $text-color,
  content: '\e80d'
);

// Restaurant Selection
$m-restaurants-card-background-color: #fff; // according to another boxes styles in theme (.m-content__content class)

// Fulfillment Widget
$m-fulfillment-widget-margin-block: 0;

// Mixins
@mixin pasja-box-shadow($offset: $pasja-box-shadow-offset-base, $color: rgba(#000, 0.05)) {
  box-shadow: -$offset $offset 0 $color;
}

@mixin pasja-blend-mode($disable-effect: false) {
  @at-root .l-blend-mode & {
    @if $disable-effect == true {
      filter: none;
      mix-blend-mode: normal;

      // IE 10 and 11 fallback
      @media screen and (-ms-high-contrast: none) {
        opacity: 1;
      }

      @supports not (mix-blend-mode: multiply) {
        opacity: 1;
      }
    } @else {
      filter: grayscale(60%) contrast(120%);
      mix-blend-mode: multiply;

      // IE 10 and 11 fallback
      @media screen and (-ms-high-contrast: none) {
        opacity: 0.7;
      }

      @supports not (mix-blend-mode: multiply) {
        opacity: 0.7;
      }
    }
  }
}

// Shared dependencies
@import 'base/shared-themes-dependencies';

// Custom styles
@import 'base/shared-essentials';
@import 'base/shared-themes';
@import 'base/pasja/overrides';
@import 'modules/m-cover-media';
@import 'modules/m-buttons';
@import 'modules/m-floating-action';
@import 'modules/m-page-subheader';

// Pasja
@import 'modules/pasja/m-section';
@import 'modules/pasja/m-header';
@import 'modules/pasja/m-brand';
@import 'modules/pasja/m-nav';
@import 'modules/pasja/m-icon-group';
@import 'modules/pasja/m-slider';
@import 'modules/pasja/m-info-box';
@import 'modules/pasja/m-info-boxes';
@import 'modules/pasja/m-scatter-gallery';
@import 'modules/pasja/m-simple-gallery';
@import 'modules/pasja/m-content';
@import 'modules/pasja/m-author';
@import 'modules/pasja/m-indicators';
@import 'modules/pasja/m-page-header';
@import 'modules/pasja/m-featured';
@import 'modules/pasja/m-teasers';
@import 'modules/pasja/m-steps';
@import 'modules/pasja/m-checkout';
@import 'modules/pasja/m-footer';
@import 'modules/pasja/m-recommended-items';

html {
  font-size: $font-size-base;
}

body {
  position: relative;
  font-weight: $font-weight-base;
  font-size: 1rem;

  &:before {
    @include position(absolute, 0 0 null 0);
    height: 25px; /* fallback */
    height: 3vw;
    max-height: 45px;
    background-color: rgba(#fff, .4);
    content: " ";

    @media screen and (min-width: $screen-md-min) {
      height: 45px;
    }
  }
}

img {
  @include img-responsive();
}

.stars {
  img {
    display: inline;
  }
}

button,
a {
  transition: color, background-color, opacity;
  transition-duration: $base-duration;
}

th,
strong {
  font-weight: normal;
}

.dropdown-menu {
  @include pasja-box-shadow(10px);
  margin: 20px 0 0;
  animation: fadeIn $base-duration;
}

.icon-ok {
  color: $brand-success;
}

// Layout for contact page
.l-contact {
  .opening-hours {
    @include make-row;

    &__heading {
      + br {
        display: none;
      }
    }

    &__group {
      @include make-md-column(6);

      &:not(:last-child) {
        margin-bottom: 22px;

        @media screen and (max-width: $grid-float-breakpoint-max) {
          margin-bottom: $base-spacing; // The same as <address> sibling.
        }
      }
    }
  }

  address {
    margin-bottom: 0;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      margin-bottom: $base-spacing;
    }
  }
}

// List module
.m-list {
  &--grid {
    .m-list {
      &__item {
        @media screen and (min-width: $screen-xs-min) {
          padding: $grid-gutter-width;
        }

        @media screen and (min-width: $grid-float-breakpoint) {
          margin-bottom: -1px;
          border-right: $m-list-item-border-top;
          border-bottom: $m-list-item-border-top;

          &:nth-child(2n) {
            @media screen and (max-width: $screen-sm-max) {
              border-right: 0;
            }
          }
        }

        @media screen and (min-width: $screen-md-min) {
          &:nth-child(3n) {
            border-right: 0;
          }
        }
      }
    }
  }

  &--grid-md-2 {
    .m-list {
      &__item {
        @media screen and (min-width: $screen-xs-min) {
          padding: $grid-gutter-width;
        }

        @media screen and (min-width: $grid-float-breakpoint) {
          margin-bottom: -1px;
          border-right: $m-list-item-border-top;
          border-bottom: $m-list-item-border-top;

          &:nth-child(2n) {
            border-right: 0;
          }
        }
      }
    }
  }

  // It is because promotion list items are beyond .restaurant-menu gutters logic (base/partials/assets/_menu.scss.liquid)
  // TODO: Separate menu list items from generic list items
  &__item--promo {
    padding: floor($grid-gutter-width / 2);
  }
}

// Group module
.m-group {
  @include pasja-box-shadow();

  &--small-shadow {
    @include pasja-box-shadow(10px);
  }

  &__header {
    &:not(:first-child) {
      border-top: 1px solid $brand-secondary; // color defined in theme.scss.liquid
    }
  }
}

// Group navigation
.m-group-nav {
  &__link {
    border-top: 1px solid $brand-secondary; // color defined in theme.scss.liquid

    @at-root .m-group-nav__list-item:first-child .m-group-nav__link {
      border-top: 0;
    }
  }

  &--horizontal {
    .m-group-nav__title {
      display: none;
    }

    .m-group-nav__link {
      padding-right: 1em;
      padding-left: 1em;
      border: none;
    }
  }

  @at-root .m-flex-layout {
    &:not(.m-flex-layout--column) {
      .m-group-nav--aside {
        margin-bottom: floor($base-spacing * 3);
      }
    }
  }
}

// Buttons
.btn {
  @include pasja-box-shadow(5px);

  @at-root .l-page-header-ls & {
    letter-spacing: ($pasja-letter-spacing * 0.6);
  }
}

.btn-special-block {
  @include button-size(15px, 15px, $btn-font-size-large, $btn-line-height-large, 0);
  display: block;
  width: 100%;
  background-color: #fff;
}

.btn-xlg {
  @include button-size(15px, 60px, $btn-font-size-large, $btn-line-height-large, $btn-border-radius-large);
}

// Letter spacing
.mobile-navigation,
.m-nav__list-item,
.m-list__title,
.m-item__title,
.m-group__header,
.m-group-nav__title,
.m-group-nav__link,
.m-restaurants__card-title,
.m-restaurants__heading {
  @at-root .l-page-header-ls & {
    letter-spacing: ($pasja-letter-spacing * 0.7);
  }
}

// Promotions availability
.promotion-availability {
  font-size: 0.9375rem;

  strong {
    font-weight: $font-weight-base;
  }

  .button {
    display: block;
  }

  > li {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;

    &:not(:first-child) {
      border-top: 1px solid $brand-secondary; // color defined in theme.scss.liquid
    }
  }

  &__label {
    margin-right: 10px;
  }
}

// Container
.container-fluid {
  &--limited {
    max-width: 1600px;
  }
}
